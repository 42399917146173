import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISearchFilter } from '@shared/interface/search.interface';
import { ERoles } from '@shared/enum';
import { IResponseAll } from '@shared/interface/response.interface';
import { IsampleList } from '@shared/list/sample.list';

@Injectable({
  providedIn: 'root',
})
export class HttpUSerService {
  private http = inject(HttpClient);
  private urlApi = environment.apiUrl + '/user';

  sellers: any[] = [];


  getSellers(roles?:string[]) {
    if (roles?.length) {
      return this.sellers.filter((s)=>roles.includes(s.role))
    }
    console.log("SELLERS == ",this.sellers)
    return this.sellers;
  }

  findAll(filter: ISearchFilter) {
    return this.http.post<any>(`${this.urlApi}/all`, { ...filter });
  }

  update(id: number, data: any) {
    this.findListSeller();
    return this.http.patch<any>(`${this.urlApi}/${id}`, { ...data });
  }

  findListSeller() {
    const filter: ISearchFilter = {
      field: ['roles', 'roles', 'roles'],
      valueField: [ERoles.SELLER_ONE, ERoles.SELLER_TWO, ERoles.ADMIN],
      isLike: true,
      orWhere:false,
      limit: 1000,
    };

    const result: IsampleList[] = [];

    this.findAll(filter).subscribe((resp: IResponseAll) => {
      resp.data.forEach((user) => {
        result.push({ id: user.id, name: user.name, role:user.roles[0] });
      });

      this.sellers = result;
      console.log("INIT SELLER == ",this.sellers)
    });
  }
}
