import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IsampleList } from '@shared/list/sample.list';

@Injectable({
  providedIn: 'root',
})
export class HttpGroupService {
  private http = inject(HttpClient);
  private urlApi = environment.apiUrl + '/client-group';

  list = [];

  findAll() {
    return this.http.get<any>(`${this.urlApi}`);
  }

  create(data: { name: string }) {
    return this.http.post<any>(`${this.urlApi}`, data);
  }

  init() {
    this.findAll().subscribe((resp) => {
      this.list = resp.data;
    });
  }

  getGroups() {
    return this.list;
  }

  getListGroup() {
    const resp: IsampleList[] = [];
    Object.values(this.list).forEach((l: any) => {
      resp.push({ id: l.id, name: l.name });
    });
    return resp;
  }
}
