import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISearchFilter } from '@shared/interface/search.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  private http = inject(HttpClient);
  private urlApi = environment.apiUrl + '/clients';

  myClienst = [];

  findAll(filter: ISearchFilter) {
    return this.http.post<any>(`${this.urlApi}/all`, { ...filter });
  }
  findOne(filter: ISearchFilter) {
    return this.http.post<any>(`${this.urlApi}/all`, { ...filter });
  }
  findOneRelations(id:number,filter: ISearchFilter) {
    return this.http.patch<any>(`${this.urlApi}/relations/all/${id}`, { ...filter });
  }
  update(id: number, data: any) {
    return this.http.patch<any>(`${this.urlApi}/${id}`, { ...data });
  }

  updateGroup(group: number, ids: any[]) {
    return this.http.post<any>(`${this.urlApi}/update-group`, { group, ids });
  }

  findMyClients(text: string, type: string) {
    return this.http.get<any>(`${this.urlApi}/myclients`, { params: { text, type } });
  }
}
